import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import instructor from './modules/instructor'
import clase from './modules/clase'
import inscritos from './modules/inscritos'
import horario from './modules/horario'
import plans from './modules/plans'
import info from './modules/info'
import suscripciones from './modules/suscripciones'
import category from './modules/category'
import pasarela from './modules/pasarela'
import grupo from './modules/grupo'
import creditos from './modules/creditos'
import productos from './modules/productos'
import prodAtributos from './modules/prodAtributos'
import prodCategorias from './modules/prodCategorias'
import prodSubCategorias from './modules/prodSubCategorias'
import carrito from './modules/carrito'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    colors:colors,
    copy:copy,
    header:header,
    logo:logo,
    main: main,
    mainmtr:mainmtr,
    profile:profile,
    usuarios: usuarios,
    instructor: instructor,
    clase: clase,
    horario: horario,
    plans: plans,
    info: info,
    suscripciones: suscripciones,
    category:category,
    pasarela:pasarela,
    grupo,
    inscritos,
    creditos,
    productos,
    prodAtributos,
    prodCategorias,
    prodSubCategorias,
    carrito,
  },
  state: {
    numberInfoToShow: 1
  },
  mutations: {
    setNumberInfoToShow(state, payload) {
      state.numberInfoToShow = payload
    }
  }
})
